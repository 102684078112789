<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>View Order No. {{ formData.orderno }}</h1>
      </b-col>
    </b-row>

    <b-card>
      <b-row>
        <b-col>
          <h2>Customer Information</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Customer" label-for="v-customer">
            {{ formData.customers_company }}
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Name" label-for="v-customers_name">
            {{ formData.customers_name }}
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Order Status" label-for="v-orderstatus">
            <b-badge pill :variant="pillOrderStatus">
              {{ previewOrderStatus }}
            </b-badge>

            <b-button
              size="sm"
              class="ml-1"
              variant="flat-primary"
              v-if="formData.orderstatus === '0'"
              @click="updateOrderStatus('1')"
            >
              Process
            </b-button>
            <b-button
              size="sm"
              class="ml-1"
              variant="flat-info"
              v-if="formData.orderstatus === '1'"
              @click="updateOrderStatus('2')"
            >
              Ready To Deliver
            </b-button>
            <b-button
              size="sm"
              class="ml-1"
              variant="flat-danger"
              v-if="formData.orderstatus === '1'"
              @click="updateOrderStatus('5')"
            >
              Cancel
            </b-button>
            <b-button
              size="sm"
              class="ml-1"
              variant="flat-warning"
              v-if="formData.orderstatus === '2'"
              @click="updateOrderStatus('3')"
            >
              OTW to Customer
            </b-button>
            <b-button
              size="sm"
              class="ml-1"
              variant="flat-success"
              v-if="formData.orderstatus === '3'"
              @click="updateOrderStatus('4')"
            >
              Delivered
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Address" label-for="v-customer_address">
            {{ previewCustomerAddress }}
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Telephone" label-for="v-customer_telephone">
            {{ formData.customers_telephone }}
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Email" label-for="v-customer_email">
            {{ formData.email }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Sales Person" label-for="v-sales_person">
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h2>Billing Information</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Company" label-for="v-company">
            {{ formData.billing_company }}
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Name" label-for="v-billing_name">
            {{ formData.billing_name }}
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Email" label-for="v-billing_email">
            {{ formData.billing_email }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Address" label-for="v-address">
            {{ previewBillingAddress }}
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Telephone" label-for="v-billing_telephone">
            {{ formData.billing_tel }}
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Fax" label-for="v-billing_fax">
            {{ formData.billing_fax }}
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h2>Delivery Information</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Company" label-for="v-company">
            {{ formData.delivery_company }}
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Name" label-for="v-delivery_name">
            {{ formData.delivery_name }}
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Email" label-for="v-delivery_email">
            {{ formData.delivery_email }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Address" label-for="v-address">
            {{ previewDeliveryAddress }}
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Telephone" label-for="v-delivery_telephone">
            {{ formData.delivery_tel }}
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Fax" label-for="v-delivery_fax">
            {{ formData.delivery_fax }}
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h2>Order Details</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <DxGrid
            :canAdd="false"
            :hasExport="false"
            :data-source="previewOrderDetails"
          >
            <template #columns>
              <DxColumn data-field="products_name" caption="Name" />
            </template>
          </DxGrid>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h2>Other Information</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Payment Method">
            {{ formData.payment_method }}
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Payment Transaction">
            {{ formData.payment_transaction }}
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Payment Date">
            {{ getDate(formData.payment_date) }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Date Purchased">
            {{ getDate(formData.date_purchased) }}
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Date Finished">
            {{ getDate(formData.orders_date_finished) }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Currency">
            {{ formData.currency }}
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Coupon">
            {{ formData.coupon_code }}
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Coupon Amount">
            {{ formData.coupon_amount }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Shipping Method">
            {{ formData.shipping_method }}
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Shipping Cost">
            {{ formData.shipping_cost }}
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Shipping Duration">
            {{ formData.shipping_duration }}
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Notes">
            {{ formData.notes }}
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="text-right">
          <b-button
            type="button"
            variant="outline-danger"
            @click="$router.push('/pages/e-commerce/orders')"
          >
            Back
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import orderApi from '@api/orders';
// @todo change from utils classes to global-function
import { unixToDisplayDateTime } from '@/utils/classes/dates';

export default {
  name: 'OrderViewPage',
  components: {},
  data: () => ({
    formData: {},
  }),
  computed: {
    pillOrderStatus: {
      get() {
        const status = this.formData.orderstatus;

        if (status === '1') {
          return 'primary';
        }

        if (status === '2') {
          return 'info';
        }

        if (status === '3') {
          return 'warning';
        }

        if (status === '4') {
          return 'success';
        }

        if (status === '5') {
          return 'danger';
        }
      },
    },
    previewOrderStatus: {
      get() {
        const status = this.formData.orderstatus;

        if (status === '1') {
          return 'Processing';
        }

        if (status === '2') {
          return 'Ready To Deliver';
        }

        if (status === '3') {
          return 'OTW to Customer';
        }

        if (status === '4') {
          return 'Delivered';
        }

        if (status === '5') {
          return 'Cancelled';
        }

        return 'New';
      },
    },
    previewCustomerAddress: {
      get() {
        const data = {
          addr1: this.formData.customers_addr1,
          addr2: this.formData.customers_addr2,
          country: this.formData.customers_country,
          postal: this.formData.customers_postal,
        };

        return this.getAddress(data);
      },
    },
    previewBillingAddress: {
      get() {
        const data = {
          addr1: this.formData.billing_addr1,
          addr2: this.formData.billing_addr2,
          country: this.formData.billing_country,
          postal: this.formData.billing_postal,
        };

        return this.getAddress(data);
      },
    },
    previewDeliveryAddress: {
      get() {
        const data = {
          addr1: this.formData.delivery_addr1,
          addr2: this.formData.delivery_addr2,
          country: this.formData.delivery_country,
          postal: this.formData.delivery_postal,
        };

        return this.getAddress(data);
      },
    },
    previewOrderDetails: {
      get() {
        return this.formData.orderdetails?.data ?? [];
      },
    },
  },
  mounted() {
    if (+this.$route.query.id) {
      this.loadOrder();
    }
  },
  methods: {
    loadOrder() {
      orderApi
        .get(+this.$route.query.id)
        .then(({ data }) => {
          this.formData = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    getAddress({ addr1, addr2, country, postal }) {
      let address = '';

      if (addr1) {
        address += addr1;
      }

      if (addr2) {
        address += ` ${addr2}`;
      }

      if (country) {
        address += ` ${country}`;
      }

      if (postal) {
        address += ` ${postal}`;
      }

      return address;
    },
    getDate(date) {
      return unixToDisplayDateTime(date);
    },
    updateOrderStatus(status) {
      this.formData.orderstatus = status;

      const params = {
        id: +this.$route.query.id,
        orderstatus: status,
      };

      orderApi
        .updateOrderStatus(params)
        .then(() => {
          //
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style></style>
